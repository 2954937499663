<script setup>
import Checkbox from '@/Components/Checkbox.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/InputError.vue'
import InputLabel from '@/Components/InputLabel.vue'
import PrimaryButton from '@/Components/PrimaryButton.vue'
import TextInput from '@/Components/TextInput.vue'
import { Head, Link, useForm } from '@inertiajs/vue3'
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue'
import { trans } from 'laravel-vue-i18n'

defineProps({
  canResetPassword: Boolean,
  status: String
})

const form = useForm({
  email: '',
  password: '',
  remember: false
})

const submit = () => {
  form.post(route('login'), {
    onFinish: () => form.reset('password')
  })
}

const setDemoCredentials = () => {
  form.email = 'admin@mail.com';
  form.password = '12345678';
};

</script>

<template>
  <GuestLayout>
    <Head :title="$t('Log in')" />

    <div class="text-center 2xl:mb-10 mb-10">
      <h4 class="text-2xl text-slate-900 dark:text-white mb-3 font-medium">
        {{ $t('Sign in') }}
      </h4>
      <div class="text-slate-500 dark:text-slate-400 text-base">
        {{ $t('Sign in to your account') }}
      </div>
    </div>

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
      {{ status }}
    </div>

    <a-form layout="vertical" @submit.prevent="submit">
      <a-form-item
        :label="$t('Email')"
        :class="[
          form.errors.email
            ? 'ant-form-item-has-feedback ant-form-item-has-error ant-form-item-with-help'
            : ''
        ]"
      >
        <a-input
          id="email"
          type="email"
          v-model:value="form.email"
          autofocus
          autocomplete="email"
          :placeholder="$t('Email')"
        >
          <template #prefix>
            <UserOutlined class="opacity-30" />
          </template>
        </a-input>

        <InputError :message="form.errors.email" />
      </a-form-item>

      <a-form-item
        :label="$t('Password')"
        :class="[
          form.errors.password
            ? 'ant-form-item-has-feedback ant-form-item-has-error ant-form-item-with-help'
            : ''
        ]"
      >
        <a-input
          id="password"
          type="password"
          v-model:value="form.password"
          autocomplete="current-password"
          :placeholder="$t('Password')"
        >
          <template #prefix>
            <LockOutlined class="opacity-30" />
          </template>
        </a-input>
        <InputError :message="form.errors.password" />
      </a-form-item>

      <div class="flex items-center justify-between mb-3">
        <a-checkbox name="remember" v-model:checked="form.remember">{{
          $t('Remember me')
        }}</a-checkbox>
        <Link :href="route('password.request')" prefetch>
          <a-button type="link" v-if="canResetPassword">
            {{ $t('Forgot your password?') }}
          </a-button>
        </Link>
      </div>

      <a-button
        :disabled="form.processing"
        htmlType="submit"
        type="primary"
        class="w-full bg-indigo-500"
      >
        {{ $t('Log in') }}
      </a-button>

      <div class="mt-5 text-center">
        <div class="p-4 border rounded bg-gray-100 text-sm mb-3">
          <p><strong>Email:</strong> admin@mail.com</p>
          <p><strong>Password:</strong> 12345678</p>
        </div>
        <a-button type="default" @click="setDemoCredentials" class="bg-gray-100">
          {{ $t('Use Admin Credentials') }}
        </a-button>
      </div>

    </a-form>

  </GuestLayout>
</template>
